import { ILocation } from "../@types/types";
import taosLocalStore from "./local.2.json";
/**
 * Utility function to compute distance in km
 * @param  {number} the latitude of position1
 * @param  {number} the longitude of position1
 * @param  {number} the latitude of position2
 * @param  {number} the longitude of position2
 * @return {number} the distance between position a and b in km
 */
export function getDistanceFromLatLonInKm(
	latitude1: number,
	longitude1: number,
	latitude2: number,
	longitude2: number) {
	const p: number = 0.017453292519943295;    //This is  Math.PI / 180
	const c = Math.cos;
	var a = 0.5 - c((latitude2 - latitude1) * p) / 2 +
		c(latitude1 * p) * c(latitude2 * p) *
		(1 - c((longitude2 - longitude1) * p)) / 2;
	var R = 6371; //  Earth distance in km so it will return the distance in km
	var dist = 2 * R * Math.asin(Math.sqrt(a));
	return dist;
}

const nyPortals = [{
	Name: 'nowhere',
	Description: undefined,
	Landmark: 'Soho',
	Coordinates: { Latitude: 40.72222404, Longitude: -74.00252167 },
	SeriesId: 1
}, { "Coordinates": { "Longitude": -74.006925, "Latitude": 40.743926 }, "SeriesId": 1, "Landmark": "High Line", "Description": "White Air West", "Id": "3cb8f098-ddc0-4bf8-975d-935ea793e0ce", "Name": "octahedron" }, { "Coordinates": { "Longitude": -74.013413, "Latitude": 40.711631 }, "SeriesId": 1, "Landmark": "9/11 Memorial", "Description": "yellow earth middle", "Id": "6f722884-7272-4e64-bc82-a96634338ce2", "Name": "cube" }, { "Coordinates": { "Longitude": -73.999849, "Latitude": 40.715818 }, "SeriesId": 1, "Landmark": "China Town", "Description": "Red Fire South", "Id": "21dc9fe0-61f4-4951-a992-c49ed428bb07", "Name": "tetrahedron" }, { "Coordinates": { "Longitude": -73.98109192031806, "Latitude": 40.72954331730929 }, "SeriesId": 1, "Landmark": "East Village", "Description": "Black Water North", "Id": "d0017ea8-6055-4b47-9daf-e589a49b3fa9", "Name": "dodecahedron" }, { "Coordinates": { "Longitude": -73.985043, "Latitude": 40.758952 }, "SeriesId": 1, "Landmark": "Times Square", "Description": "Green Wood East", "Id": "6704f91c-4e2b-4681-a678-3b0a355900fd", "Name": "icosahedron" }]

const taosPortals = [{
	"Coordinates": { "Latitude": 36.410954, "Longitude": -105.571082 },
	"SeriesId": 2,
	"Landmark": "Revolt Gallery",
	"Description": "Black Water North",
	"Name": "icosahedron"
}, {
	"Coordinates": { "Latitude": 36.40752, "Longitude": -105.57474 },
	"SeriesId": 2,
	"Landmark": "Taos Plaza",
	"Description": "White Air West",
	"Name": "circle"
}, {
	"Coordinates": { "Latitude": 36.485634, "Longitude": -105.734431 },
	"SeriesId": 2,
	"Landmark": "Gorge Bridge",
	"Description": "Yellow Earth Middle",
	"Name": "cube"
}, {
	"Coordinates": { "Latitude": 36.3581127, "Longitude": -105.60790 },
	"SeriesId": 2,
	"Landmark": "Ranchos de Taos Plaza",
	"Description": "Red Fire South",
	"Name": "tetrahedron"
}, {
	"Coordinates": { "Latitude": 36.4447007, "Longitude": -105.5938911 },
	"SeriesId": 2,
	"Landmark": "Millicent Rogers Museum",
	"Description": "White Air West",
	"Name": "octahedron"
}, {
	"Coordinates": { "Latitude": 36.40851, "Longitude": -105.56485 },
	"SeriesId": 2,
	"Landmark": "Mabel Dodge Luhan House",
	"Description": "Green Wood East",
	"Name": "dodecahedron"
}]
/**
 * Utitliy method to generate static Portal locations
 * @return {[ILocation]} Array of @ILocation conforming Portals
 */
export function getPortals(): ILocation[] {
	return [...taosPortals, ...nyPortals]
}

export async function apiGetPortals() {
	let portals: ILocation[] = new Array<ILocation>;
	portals = await fetch('')
		.then(response => response.json())
		.then(data => { portals = data; return portals });
	return portals;
}
