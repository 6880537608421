import React, { Component } from 'react';
import ModelViewer from '../ModelViewer';
import { ILocation, IPosition } from '../../@types/types';
import './Detail.css';

//import InstallationImages from '../../res';
import Installations from '../../res/ar/';

interface IDetailProps {
  installation: ILocation,
  position: IPosition,
  onClose: () => void
}



class Detail extends Component<IDetailProps, { ar: boolean }> {
  state = {
    ar: true
  }
  render() {
    const { installation } = this.props;
    const distanceToInstalltionInMiles = installation.Distance && (installation.Distance * 0.6213).toFixed(2);
    //insert any geofencing here, i.e. (getDistanceFromLatLonInKm(this.props.position, installation.location) < installation.perimeter) ? this.setState({ar: true}) : this.setState({ar: false})
    return (
      <div className='Detail'>
        {
          //<div className="Link" style={{position: 'absolute', right: 20, bottom: 20, textDecoration: 'underline'}}><span onClick={()=>this.setState({ar: !this.state.ar})}>{`${this.state.ar ? 'Dis' : 'En'}able AR`}</span></div>
        }<div style={{ marginBottom: 10, bottom: 0 }}>
          <div>
            <h4>
              {installation.Landmark.toUpperCase()}
              {distanceToInstalltionInMiles && <span style={{ fontSize: 12, float: 'right' }}>{distanceToInstalltionInMiles} Miles to Portal</span>}
            </h4>
          </div>
          <ModelViewer
            ar={this.state.ar}
            src={Installations.andr[installation.Name]}
            iosSrc={Installations.ios[installation.Name]}
          />
          <div style={{ paddingTop: 8, display: 'flex', flexDirection: 'row' }}>
            <div style={{ flexGrow: 1, float: 'left' }}>
              <span>{installation.Name.toUpperCase()}</span>
            </div>
            {
              // <div style={{ right: 0, float: 'right' }}>
              //   <div>
              //     <div>{installation.Description && installation.Description.split(' ').map((d, i) => <span key={i}>{d.toUpperCase()} </span>)}</div>
              //   </div>
              // </div> 
            }
          </div>

          {/*<div style={{textDecoration: 'underline', marginRight: '20px'}}onClick={()=>onClose()}>Return To Map</div>*/}
        </div>

      </div>
    )
  }

}

export default Detail;
