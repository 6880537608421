import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './Menu.css';


/* App.jsx */
class App extends React.Component<{items: {path: string, name: string}[]},{menuOpen: boolean}> {

  state={
      menuOpen:false,
    }


  handleMenuClick() {
    this.setState({menuOpen:!this.state.menuOpen});
  }

  handleLinkClick() {
    this.setState({menuOpen: false});
  }

  render(): JSX.Element{
    const {items, children} = this.props;
    const styles=
      {
        logo: {
          margin: '0 auto',
        },
        body: {
          filter: this.state.menuOpen ? 'blur(2px)':null
        }

      }

    const menuItems = items.map((val,index)=>{
      return (
        <MenuItem
          key={index}
          delay={`${index * 0.1}s`}
          onClick={()=>{this.handleLinkClick();}}><Link to={val.path}>{val.name}</Link></MenuItem>)
    });

    return(
      <div>
        <div className={'MenuContainer'}>
          <MenuButton open={this.state.menuOpen} onClick={()=>this.handleMenuClick()} color='white'/>
        </div>
        <Menu open={this.state.menuOpen}>
          {menuItems}
        </Menu>
        <div className={'MenuContainerBody'}>
          {children}
        </div>
      </div>
    )
  }
}

/* MenuItem.jsx*/
class MenuItem extends React.Component<{delay: string, onClick: ()=>void}, {hover: boolean}>{

  state = {
      hover:false
  }

  handleHover(){
    this.setState({hover:!this.state.hover});
  }

  render(){
    const styles={
      container: {
        opacity: 0,
        animation: '1s appear forwards',
        animationDelay:this.props.delay,
      },
      menuItem:{
        fontFamily:`'Open Sans', sans-serif`,
        fontSize: '1.2rem',
        padding: '1rem 0',
        margin: '0 5%',
        cursor: 'pointer',
        color: this.state.hover? 'gray':'#fafafa',
        transition: 'color 0.2s ease-in-out',
        animation: '0.5s slideIn forwards',
        animationDelay:this.props.delay,

      },
      line: {
        width: '90%',
        height: '1px',
        background: 'gray',
        margin: '0 auto',
        animation: '0.5s shrink forwards',
        animationDelay:this.props.delay,

      }
    }
    return(
      <div style={styles.container}>
        <div
          style={styles.menuItem}
          onMouseEnter={()=>{this.handleHover();}}
          onMouseLeave={()=>{this.handleHover();}}
          onClick={this.props.onClick}
        >
          {this.props.children}
        </div>
      <div style={styles.line}/>
    </div>
    )
  }
}

/* Menu.jsx */
class Menu extends React.Component<{open: boolean},{open: boolean}> {
  constructor(props: {open: boolean}){
    super(props);
    this.state={
      open: this.props.open? this.props.open:false,
    }
  }

  componentWillReceiveProps(nextProps: {open: boolean}){
    if(nextProps.open !== this.state.open){
      this.setState({open:nextProps.open});
    }
  }

  render(){
    const styles={
      container: {
        height: this.state.open? '100vh': 0,
      },
      menuList: {
        paddingTop: '3rem',
      }
    }
    return(
      <div style={styles.container} className={'Menu'}>
        {
          this.state.open?
            <div style={styles.menuList}>
              {this.props.children}
            </div>:null
        }
      </div>
    )
  }
}

interface MenuButtonProps {open: boolean, color: string, onClick: ()=>void}

/* MenuButton.jsx */
class MenuButton extends React.Component<MenuButtonProps,{open: boolean, color: string}> {
  constructor(props: MenuButtonProps){
    super(props);
    this.state={
      open: this.props.open? this.props.open:false,
      color: this.props.color? this.props.color:'black',
    }
  }

  componentWillReceiveProps(nextProps: MenuButtonProps){
    if(nextProps.open !== this.state.open){
      this.setState({open:nextProps.open});
    }
  }

  handleClick(){
    this.setState({open:!this.state.open});
  }

  render(){
    const styles = {
    line: {
        height: '2px',
        width: '20px',
        background: this.state.color,
        transition: 'all 0.2s ease',
      },
      lineTop: {
        transform: this.state.open ? 'rotate(45deg)':'none',
        transformOrigin: 'top left',
        marginBottom: '5px',
      },
      lineMiddle: {
        opacity: this.state.open ? 0: 1,
        transform: this.state.open ? 'translateX(-16px)':'none',
      },
      lineBottom: {
        transform: this.state.open ? 'translateX(-1px) rotate(-45deg)':'none',
        transformOrigin: 'top left',
        marginTop: '5px',
      },
    }
    return(
      <div className={'MenuButton'}
        onClick={this.props.onClick ? this.props.onClick:
          ()=> {this.handleClick();}}>
        <div style={{...styles.line,...styles.lineTop}}/>
        <div style={{...styles.line,...styles.lineMiddle}}/>
        <div style={{...styles.line,...styles.lineBottom}}/>
      </div>
    )
  }
}


export default App;
