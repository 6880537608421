import React, { Component } from 'react';
require('@google/model-viewer');
const modelViewerStyles = {
  width: 'auto',
  minHeight: '40vh',
  backgroundColor: 'white'
}
interface IModelViewerProps {
  src: string
  iosSrc: string
  ar: boolean
}

const Viewer:React.FC<{src:string ,iosSrc: string,ar:boolean}> = ({src,iosSrc,ar})=> ar ?
//@ts-ignore
(<model-viewer
  ar
  ar-modes={"webxr scene-viewer quick-look"}
  ios-src={iosSrc}
  src={src}
  alt="GO02"
  auto-rotate
  controls
  camera-controls
  style={modelViewerStyles} />) :
  //@ts-ignore
  (<model-viwer
    ios-src={iosSrc}
    src={src}
    alt="GO02"
    auto-rotate
    controls
    style={modelViewerStyles}
      />)

class ARModelViewer extends Component<IModelViewerProps> {

  render() {
    const {src, iosSrc, ar} = this.props;
    return (
      <div>
        <Viewer src={src} iosSrc={iosSrc} ar={ar} />
      </div>
    );
  }

}

export default ARModelViewer;
