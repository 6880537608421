const Installations: { [key: string]: any } = {
  ios: {
    cube: require('./cube.usdz').default,
    circle: require('./circle.usdz').default,
    dodecahedron: require('./dodecahedron.usdz').default,
    icosahedron: require('./icosahedron.usdz').default,
    tetrahedron: require('./tetrahedron.usdz').default,
    octahedron: require('./octahedron.usdz').default,
    nowhere: require('./nowhere.usdz').default
  },
  andr: {
    cube: require('./cube.glb').default,
    circle: require('./circle.glb').default,
    dodecahedron: require('./dodecahedron.glb').default,
    icosahedron: require('./icosahedron.glb').default,
    tetrahedron: require('./tetrahedron.glb').default,
    octahedron: require('./octahedron.glb').default,
    nowhere: require('./nowhere.glb').default
  }
}

export default Installations;
