import React, { useState, useEffect } from 'react';
import './App.css';

import { Route, Link, Switch } from 'react-router-dom';
// import {slide as Menu} from 'react-burger-menu';

import { getPortals } from './service';
import { Home, LandingPage, About, Menu, HowTo } from './components';
import { ILocation, IPosition } from './@types/types';
import Images from './res';

const portals: ILocation[] = getPortals();

const menuItems: { path: string, name: string }[] = [
  {
    path: '/go02',
    name: 'Map'
  }, /*{
    path: '/about',
    name: 'About'
  },*/ {
    path: '/howto',
    name: 'How To Use'
  }
]

function Head({ open } = { open: false }) {
  return (
    <Menu items={menuItems} />)
}

function App() {
  const TAOS_STARTING_POSITION: IPosition = { Latitude: 36.41135218438864, Longitude: -105.62628665890824 }
  const [appState, setAppState] = useState({
    installations: new Array<ILocation>,
    isLoading: true
  })

  return (<div className="App">
    <div style={{ height: '98vh' }}>

      <Switch>
        <Route path='/' exact>
          <LandingPage
            src={Images.landing}
          />
        </Route>
        <Route path='/go02' exact>
          <Menu items={menuItems}>
            <Home
              installations={portals}
              mapkey={'AIzaSyADFAs-9MakSokkObAKNzkzuwqdMWVm-jg'}
              zoom={11}
              title={'GO02'}
              position={TAOS_STARTING_POSITION}
            />
          </Menu>
        </Route>
        {
          // <Route path='/about'>
          //   <Menu items={menuItems}>
          //     <About />
          //   </Menu>
          // </Route>
        }
        <Route path='/howto'>
          <Menu items={menuItems}>
            <HowTo />
          </Menu>
        </Route>
      </Switch>

    </div>
  </div>
  );
}

export default App;
