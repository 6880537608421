import React from 'react';
import {Link} from 'react-router-dom'

const LandingPage: React.FC<{src: string}> = ({src}) => (
  <div>
    <div style={{overflow:'hidden', textAlign:'center'}}>
      <div style={{marginTop: '30%'}}>
        <img src={src} alt="GO02_NY" width='100%' />
      </div>
      <div style={{position:'fixed', top: '74%', textAlign: 'center', textDecoration: 'underline', width: '100%'}}>
        <Link to="/go02">START EXPERIENCE</Link>
      </div>
    </div></div>
);

export default LandingPage;
