import React, { Component } from 'react'
import './Map.css';
import GoogleMapReact from 'google-map-react';
import { ILocation, IPosition, IMarkerEventTarget } from '../../@types/types';
import mapStyles from './gray.layout';


const styles = {
  width: '100%',
  height: '100%'
};

const createMapOptions = (maps: GoogleMapReact.Maps) => {
  return {
    styles: mapStyles,
    fullscreenControl: false
  }
}

interface IMapProps {
  center: GoogleMapReact.Coords,
  zoom: number,
  mapkey: string,
  installations: ILocation[],
  onMarkerClick: (portalTarget: IMarkerEventTarget) => void,
  currentPosition: IPosition
};

const Marker: React.FC<{
  text: IMarkerEventTarget,
  imgsrc: string,
  lat: number,
  lng: number,
  onClick: (ev: IMarkerEventTarget) => void
}> = ({ text, imgsrc, onClick }) => (
  <div className="Marker" onClick={(ev) => { ev.stopPropagation(); onClick(text); }}>
    <img width={'20px'} height={'auto'} src={imgsrc} alt={`${text.Name}-${text.SeriesId}`} />
  </div>
)

const MyLocation: React.FC<{
  lat: number,
  lng: number,
  text: string,
  distance: number
}> = ({ text, distance }) => (
  <div className="MyLocation"></div>
)

export default class Map extends Component<IMapProps> {

  render() {
    const { mapkey, center, currentPosition, zoom, installations, onMarkerClick } = this.props;
    return (
      <div style={styles} className='Map' onClick={(ev) => onMarkerClick({ Name: ' ', SeriesId: 0 })}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: mapkey }}
          center={center}
          defaultZoom={zoom}
          options={createMapOptions}
        >
          {
            installations[0].Distance && <MyLocation
              text={installations[0].Name}
              distance={installations[0].Distance}
              lat={currentPosition.Latitude}
              lng={currentPosition.Longitude}
            />
          }
          {
            //@ts-ignore
            installations.map((installation, i) => <Marker
              key={i}
              onClick={onMarkerClick}
              text={{ Name: installation.Name, SeriesId: installation.SeriesId || 0 }}
              imgsrc={require(`./markers/${installation.Name}.png`).default}
              lat={installation.Coordinates.Latitude}
              lng={installation.Coordinates.Longitude}

            />)
          }

        </GoogleMapReact>
      </div>
    )
  };
}
