import React from 'react';
import { HowToImage, HowToMapImage } from '../../res';

const HowTo = ({ }) => (
  <div style={{ marginLeft: 'auto' }}>
    <img width='100%' height='auto' src={HowToImage} />
    <div>
      <ol>
        <li>Scan QR Code with the camera on your phone.</li>
        <li>Click to activate the application.</li>
        <li>Activate a marker on the map.</li>
        <li>Enter immersive AR mode by clicking small icon in the bottom right.</li>
        <li>Point your camera to the ground. Use one finger to anchor the sculpture.</li>
        <li>Use two fingers with a pinching motion to scale the sculpture bigger or smaller.</li>
        <li>Take photos with the sculpture!</li>
        <li>Share your photos!</li>
      </ol>
      #gopaseo22<br />
      @_imagima<br />
      @paseoproject<br />
      <br />
    </div>
    <img width='100%' height='auto' src={HowToMapImage} />
  </div>
);

export default HowTo;
