import React, { Component } from 'react';
import { ILocation, IPosition, IMarkerEventTarget } from '../../@types/types';
import { getDistanceFromLatLonInKm as distanceToPortal, getDistanceFromLatLonInKm } from '../../service';
import Detail from '../Detail';

import Map from '../Map';

interface IHomeProps {
  installations: ILocation[]
  mapkey: string
  zoom: number
  title: string
  position?: IPosition
}

interface IHomeState {
  position: IPosition
  sortedInstallations: ILocation[]
  atInstallation: boolean
  selectedInstallation: ILocation | undefined
  showAllInstallationDetails: boolean
  hasGeolocation: boolean
};

class Home extends Component<IHomeProps, IHomeState> {
  state = {
    sortedInstallations: this.props.installations,
    //default close to Madison Square --> 40.74284326914396, -73.98645744427084
    position: this.props.position || this.props.installations[0].Coordinates,
    //{latitude: 40.74284326914396, longitude: -73.98645744427084},
    atInstallation: false,
    selectedInstallation: undefined,
    showAllInstallationDetails: false,
    hasGeolocation: false
  }

  wid: number = -1

  distanceFromClosestPortal: (location: IPosition, installations: ILocation[]) => ILocation[] = (location, installations) => installations.map(portal => {
    const distance = distanceToPortal(location.Latitude,
      location.Longitude,
      portal.Coordinates.Latitude,
      portal.Coordinates.Longitude)
    return { ...portal, ...{ distance } }
  }).sort((a, b) => a.distance > b.distance ? 1 : -1);

  midPoint: (a: IPosition, b: IPosition) => IPosition = (a, b) => {
    return {
      Latitude: (a.Latitude + b.Latitude) / 2,
      Longitude: (a.Longitude + b.Longitude) / 2,
    }
  }

  processLocationUpdate = (position: GeolocationPosition) => {

    const { installations } = this.props;
    console.log(position);
    const coords = { "Latitude": position.coords.latitude, "Longitude": position.coords.longitude }
    const newPosition: IPosition = getDistanceFromLatLonInKm(coords.Latitude, coords.Longitude, this.state.position.Latitude, this.state.position.Longitude) > 0.2 || this.state.selectedInstallation ?
      coords :
      this.state.position;
    this.setState({
      position: newPosition,
      sortedInstallations: this.distanceFromClosestPortal(coords, installations)
    })
  }

  processLocationError = (err: GeolocationPositionError) => {
    console.log('LocationError ', err)
    this.setState({
      hasGeolocation: false,
    })
  }

  componentDidMount() {
    if ("geolocation" in navigator) {
      this.setState({ hasGeolocation: true })
      navigator.geolocation.getCurrentPosition(this.processLocationUpdate, this.processLocationError, { enableHighAccuracy: true, })
      // this.wid = navigator.geolocation.watchPosition(this.processLocationUpdate, this.processLocationError, {enableHighAccuracy: true, })
    }
  }

  componentWillUnmount() {
    if (this.state.hasGeolocation && this.wid > -1) navigator.geolocation.clearWatch(this.wid)
  }

  onMarkerClick = (ev: IMarkerEventTarget) => {

    const [selectedInstallation] = this.state.sortedInstallations.filter(v => v.Name === ev.Name && v.SeriesId === ev.SeriesId)
    this.setState({
      selectedInstallation
    })
  }

  render() {
    let { mapkey, zoom, title } = this.props
    let { position, sortedInstallations, selectedInstallation, hasGeolocation } = this.state;
    //@ts-ignore
    let center: IPosition = selectedInstallation?.location || position
    return (

      <div style={{ height: '86vh', display: 'flex', flexDirection: 'column' }}>
        <Map
          installations={sortedInstallations}
          mapkey={mapkey}
          zoom={zoom}
          onMarkerClick={this.onMarkerClick}
          // onMapClick={this.onMapClick}
          center={
            //@ts-ignore
            { lat: center.Latitude, lng: center.Longitude }
          }
          currentPosition={position}
        />
        {selectedInstallation &&
          <Detail
            onClose={() => this.setState({ selectedInstallation: undefined })}
            installation={selectedInstallation!}
            position={position}
          />
        }
      </div>
    );
  }

}

export default Home;
